import { css as original } from '@custom/defaults/theme/Components/HeaderAuth/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  headerWrap: {
    alignItems: 'flex-start',
  },
});

export default css;
