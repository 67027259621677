import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { black, green, primary, white, primaryDarken, secondary } from '../../../colors';
import Color from 'color';

import { css as original } from '@custom/defaults/theme/Components/Button/styles';
import { merge } from 'lodash';
import { generalBorderRadius } from '../../../variables';

export const css = merge(original, {
  button: {
    lineHeight: '1 !important',
    display: 'flex',
    alignItems: 'center',
    '&.ant-btn-lg': {
      height: 50,
    },
    '&.ant-btn-round': {
      borderRadius: `${generalBorderRadius} !important`,
    },
    '&.ant-btn-sm': {
      fontSize: '.875em !important',
    },
    '&.ant-btn-round.ant-btn-lg': {
      padding: '16px 20px !important',
      '& .anticon': {
        position: 'relative',
        top: 2,
      },
    },
    '&.ant-btn-primary:not([disabled])': {
      background: `${primary} !important`,
      color: `${white} !important`,
      fontWeight: 'bold',
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
      '& > *': {
        position: 'relative',
        zIndex: 1,
      },
      '&:hover': {
        color: `${white} !important`,
        background: `${primaryDarken} !important`,
      },
      '&:active, &:focus': {
        background: `${Color(primaryDarken).darken(0.2).rgb().string()} !important`,
      },
      '&:before': {
        content: 'none',
      },
    },
    '&.green': {
      background: `${green} !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
    },
    '&.secondary': {
      background: `${secondary} !important`,
      color: `${black} !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
    },
    '&.wide': {
      width: '100%',
      justifyContent: 'center',
    },
  },
  button2: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    button: {
      fontSize: '0.875rem',
      padding: ['0.3125rem', '1.25rem'],
      '&.ant-btn-lg': {
        fontSize: '1rem',
      },
    },
  },
});

export default css;
