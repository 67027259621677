import { helpers } from '@custom/defaults/theme/Global/helpers';
import { global } from './global';
import { antdPatches } from '@custom/defaults/theme/Global/antdPatches';
import { dadata } from '@custom/defaults/theme/Global/dadata';
import { notify } from '@custom/defaults/theme/Global/notify';
import { animation } from '@custom/defaults/theme/Global/animation';
import { fonts } from './fonts';

export const APP_STYLES = {
  '@global': {
    ...fonts,
    ...animation,
    ...global,
    ...helpers,
    ...antdPatches,
    ...dadata,
    ...notify,
  },
};
