// customer mrcash

export const NODE_ENV_PRODUCTION = process.env.NODE_ENV === 'production';

export const TENANT_DOMAIN = process.env.NEXT_PUBLIC_TENANT_DOMAIN;

export const SMS_SEND_DELAY = 30000;
export const EMAIL_SEND_DELAY = 30000;
export const STATUS_CHECK_INTERVAL = 20000;
export const REFRESH_DATA_INTERVAL = 60000;

export const DADATA_TOKEN = '45f17a14725a77707ab5e0a4ffe55181d871e270';
export const LOCALE = 'ru';
export const PHONE_MASK = '8';
export const CURRENCY = 'RUR';

export const HEADER_INVERTED = true;
export const MAX_DOWNLOAD_FILE_SIZE = 10; // Megabytes
export const MIN_FIAS_LVL = 7;

export const OPTIONS = {};

export const WDYR = false;

export const INTEGRATIONS = {
  yandexMetrika: {
    id: 95546541,
  },
  gtm: {
    id: 'GTM-MBNCTC38',
  },
};

export const SAVE_COOKIES = [
  'transaction_id',
  'affiliate_id',
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_campaign',
  'utm_term',
  'click_id',
];
export const SAVE_COOKIES_MAP = {
  transaction_id: 'TRANSACTION_ID',
  affiliate_id: 'AFFILIATE_ID',
  utm_source: 'UTM_SOURCE',
  utm_medium: 'UTM_MEDIUM',
  utm_content: 'UTM_CONTENT',
  utm_campaign: 'UTM_CAMPAIGN',
  utm_term: 'UTM_TERM',
  click_id: 'CLICK_ID',
};
export const SAVE_COOKIES_TTL = 60; //days

export const SENTRY_ENVIRONMENT = NODE_ENV_PRODUCTION ? 'production' : 'local';
export const SENTRY_DSN = 'https://d82941e8749c4c819acc30be6213c92a@sentry-ru.brainysoft.ru/5';
export const SENTRY_TRACE_SAMPLE_RATE = 0.1;

export const DENY_CLICKTRACKER_LINK = null;
