import { css as original } from '@custom/defaults/theme/Components/HeaderPhone/styles';
import { merge } from 'lodash';

export const css = merge(original, {
  phoneNumber: {
    // letterSpacing: 1,
    fontFamily: 'TravelsBlack, sans-serif',
  },
});

export default css;
