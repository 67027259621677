import { global as original } from '@custom/defaults/theme/Global/global';
import { merge } from 'lodash';

export const global = merge(original, {
  html: {
    fontSize: 18,
  },
  body: {
    fontFeatureSettings: '"pnum"',
  },
  'h1, h2, h3, h4, h5, h6': {
    fontFamily: 'CommonsClassic, sans-serif',
    fontWeight: 'bold',
  },
});
