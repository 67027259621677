import Color from 'color';
import { mergeThemeColors } from '@brainysoft/lk-custom-kernel/utils/merge-theme-colors';
import { colors } from './theme/Styles';

export const THEME_COLORS = mergeThemeColors(colors);

/** @deprecated */ export const white = THEME_COLORS.white;
/** @deprecated */ export const black = THEME_COLORS.black;
/** @deprecated */ export const primary = THEME_COLORS.primary;
/** @deprecated */ export const primaryDarken = THEME_COLORS.primaryDarken;
/** @deprecated */ export const primaryLight = THEME_COLORS.primaryLight;
/** @deprecated */ export const primaryDark = THEME_COLORS.primaryDark;
/** @deprecated */ export const grey = THEME_COLORS.grey;
/** @deprecated */ export const greyDark = THEME_COLORS.greyDark;
/** @deprecated */ export const greyLight = THEME_COLORS.greyLight;
/** @deprecated */ export const greyLighter = Color(THEME_COLORS.grey).lighten(0.3).rgb().string();
/** @deprecated */ export const greyText = THEME_COLORS.greyText;
/** @deprecated */ export const greyLightText = THEME_COLORS.greyLightText;
/** @deprecated */ export const red = THEME_COLORS.red;
/** @deprecated */ export const green = THEME_COLORS.green;
/** @deprecated */ export const blue = THEME_COLORS.blue;
/** @deprecated */ export const yellow = THEME_COLORS.yellow;
/** @deprecated */ export const pageBackground = THEME_COLORS.pageBackground;
/** @deprecated */ export const primaryHover = THEME_COLORS.primaryHover;
/** @deprecated */ export const primaryBackground = THEME_COLORS.primaryBackground;

/** @deprecated */ export const secondary = THEME_COLORS.secondary;
/** @deprecated */ export const secondaryBackground = THEME_COLORS.secondaryBackground;

/** @deprecated */ export const highlightColor = THEME_COLORS.highlightColor;
/** @deprecated */ export const disabledBackground = THEME_COLORS.disabledBackground;

/** @deprecated */ export const footerBackground = THEME_COLORS.primary;
