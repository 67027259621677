import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { footerBackground, greyLightText, primary, white } from '@brainysoft/lk-custom/colors';
import { generalTransition } from '@brainysoft/lk-custom/variables';

import { merge } from 'lodash';
import { css as original } from '@custom/defaults/theme/Components/Footer/styles';
import Color from 'color';

const textColor = Color(primary).lighten(1).rgb().string();

export const css = merge(original, {
  footerWrapper: {
    '& a': {
      lineHeight: 1.2,
      color: white,
      opacity: 1,
      transition: generalTransition,
      textDecoration: 'none',
      '&:hover': {
        opacity: 1,
      },
    },
  },
  footerContainer: {
    display: 'flex',
  },
  leftSide: {
    flexBasis: '33%',
    padding: '1.875rem 1rem 1.875rem 2rem',
    '& [class^="logoImg"]': {
      maxWidth: 150,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rightSide: {
    flexBasis: '67%',
    padding: '1.875rem 2rem 1.875rem 1rem',
  },
  footerMenu: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginBottom: '2rem',
  },
  footerMenuItem: {
    listStyle: 'none',
    padding: '.25rem',
    fontSize: '.9125rem',
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      paddingBottom: 0,
    },
    '& a': {
      fontSize: '0.9125rem',
    },
  },
  footerContactLabel: {
    color: textColor,
    fontSize: '.75rem',
  },
  footerContactContent: {
    color: white,
    marginBottom: '.25rem',
  },
  footerDisclaimer: {
    fontSize: '.75rem',
    color: textColor,
    '& a': {
      color: white,
    },
  },
  footerDisclaimerItem: {
    '&:not(:last-child)': {
      marginBottom: '.5rem',
    },
  },
  copyright: {
    fontSize: '.75rem',
    color: textColor,
    marginTop: '1rem',
    '& > :not(:last-child)': {
      marginBottom: '1rem',
    },
  },
});

export default css;
