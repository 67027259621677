import { css as original } from '@custom/defaults/theme/Components/AuthPage/styles';
import { merge } from 'lodash';
import { colors } from '../../Styles';
import { primary } from '@brainysoft/lk-custom/colors';
import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { authBackground, logoInverted } from '@brainysoft/lk-custom/variables';

const { darkViolet } = colors;

export const css = merge(original, {
  authBackgroundImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: '50%',
    background: `url(${authBackground}) no-repeat`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: primary,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 'auto',
      right: 'auto',
      left: '1rem',
      bottom: '1rem',
      width: '10rem',
      height: '4rem',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${logoInverted})`,
      backgroundSize: 'contain',
    },
  },
  pageTitle: {
    fontSize: '3.5rem',
    lineHeight: 1,
    fontFamily: 'TrailersTrial, sans-serif',
    '& .violet': {
      color: darkViolet,
    },
    '& .green': {
      color: primary,
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    pageTitle: {
      fontSize: '2rem',
    },
  },
});

export default css;
