export const fonts = {
  '@font-face': [
    {
      fontFamily: 'TrailersTrial',
      src: 'url(https://cdn.brainysoft.ru/lk/tenants/f89ece68-c583-435e-8849-82e02437a840/fonts/trailers_trial.ttf)',
    },
    {
      fontFamily: 'TravelsBlack',
      src: 'url(https://cdn.brainysoft.ru/lk/tenants/f89ece68-c583-435e-8849-82e02437a840/fonts/travels_black.ttf)',
    },
    {
      fontFamily: 'CommonsClassic',
      src: 'url(https://cdn.brainysoft.ru/lk/tenants/f89ece68-c583-435e-8849-82e02437a840/fonts/commons_classic_medium.woff)',
      fontWeight: 'bold',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'CommonsClassic',
      src: 'url(https://cdn.brainysoft.ru/lk/tenants/f89ece68-c583-435e-8849-82e02437a840/fonts/commons_classic_regular.woff)',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
  ] as any,
};
