import { css as original } from '@custom/defaults/theme/Components/Logo/styles';
import { merge } from 'lodash';
import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { logo, logoInverted } from '@brainysoft/lk-custom/variables';

export const css = merge(original, {
  logoImg: {
    '&.auth': {
      backgroundImage: 'none',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    logoImg: {
      '&.auth': {
        backgroundImage: `url(${logo})`,
      },
    },
  },
});

export default css;
