import headerRu from './translations/ru/header.json';
import printFormsRu from './translations/ru/printForms.json';
import widgetsRu from './translations/ru/widgets.json';

export const translations = {
  ru: {
    header: headerRu,
    printForms: printFormsRu,
    widgets: widgetsRu,
  },
};
