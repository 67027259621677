const Color = require('color');

const primary = 'rgb(69,160,65)';
const secondary = 'rgb(175,214,172)';

module.exports = {
  white: 'rgb(255,255,255)',
  black: 'rgb(0,0,0)',
  primary: primary,
  primaryDarken: Color(primary).darken(0.3).rgb().string(),
  primaryLight: Color(primary).lighten(0.3).rgb().string(),
  primaryDark: Color(primary).darken(0.7).rgb().string(),
  grey: 'rgb(180,180,180)',
  greyDark: 'rgb(120,120,120)',
  greyLight: 'rgb(220,220,220)',
  greyLighter: Color('rgb(180,180,180)').lighten(0.3).rgb().string(),
  greyText: 'rgb(60,60,60)',
  greyLightText: 'rgb(180,180,180)',
  red: 'rgb(240,20,20)',
  blue: 'rgb(0,160,220)',
  green: 'rgb(0,190,40)',
  yellow: 'rgb(250,150,0)',
  pageBackground: 'rgb(243,243,243)',
  primaryHover: 'rgb(252,255,251)',
  primaryBackground: 'rgb(250,255,249)',

  secondary: secondary,
  secondaryBackground: 'rgb(250,255,250)',

  highlightColor: 'rgb(245,20,20)',
  disabledBackground: 'rgb(250,250,250)',

  darkViolet: '#525870',
};
